import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Badges from "../components/Repeating/Badges";
import Testimonials from "../components/Repeating/Testimonials";
import FindYourCPA from "../components/Repeating/FindYourCPA";
import Locations from "../components/Repeating/Locations";
import Subscribe from "../components/Repeating/Subscribe";
import RecentPosts from "../components/Repeating/RecentPosts";
import CallToAction from "../components/Repeating/CTA";
import ModalVideo from "../components/Modal/ModalVideo";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";

const Page = ({ data }) => {
  const [autoPlay, setAutoPlay] = useState(false);

  const handlePlayVideo = () => {
    setAutoPlay(true);
  };

  return (
    <Layout>
      <SearchEngineOptimization
        title="Cannabis Accounting Services | Dark Horse CPAs"
        description="You have enough to worry about managing your cannabis business. Let us help you manage the books with our cannabis accounting services. Contact us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      

      

      
     

          <section className="mb-24 pt-20 md:mb-20">
        <div className="container">
        <header className="mb-10 text-left md:mb-18">
        <h1 className="">Cannabis Accounting Services</h1>
       
        </header>
          <div className="grid gap-y-10 md:grid-cols-3 md:gap-x-5 lg:gap-x-10">
            <div>
              <AniLink
                fade
                to="/cannabis-taxes/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                  <GatsbyImage
                    image={data.taxes.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Maximize Tax Savings</h3>
                <div className="mb-0">
                Taxes can be a buzz kill. Cannabis companies are hamstrung by IRS Section 280E, but we implement tax strategies to minimize your taxes and save you money.
                </div>
              </AniLink>
            </div>
            <div>
              <AniLink
                fade
                to="/cannabis-bookkeeping/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                  <GatsbyImage
                    image={data.keeping.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Expert Bookkeeping</h3>
                <div className="mb-0">
                Your company’s bookkeeping is the basis for all financial decisions in your business. Our expert bookkeeping services are the bedrock of our engagements.
                </div>
              </AniLink>
            </div>
            <div>
              <AniLink
                fade
                to="/cannabis-cfo/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                  <GatsbyImage
                    image={data.cfo.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Next Level CFO</h3>
                <div className="mb-0">
                The best financials in the world are just numbers on a page if you can’t interpret what those numbers mean. Our CFO advisory can take your business to the next level.
                </div>
              </AniLink>
            </div>
          </div>
        </div>
      </section>

   





      <section className="pt-20 pb-20 md:pb-32">
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
        <div className="md:col-span-8 md:col-start-1">
        <GatsbyImage image={data.services.childImageSharp.gatsbyImageData} className="rounded-2xl"/>

          </div>
        <div className="md:col-span-4 md:col-end-13">
            <h2>Planning for Your Business’s Future</h2>
            <p>
            The cannabis industry is evolving and rapidly changing all across the country. We provide extensive tax planning and financial forecasting to help you plan for what’s ahead.
            </p>
            <ButtonWithIcon
              href="/cannabis-advisory-services/"
              text="Learn More"
              altStyle={1}
            />
          </div>
          
          
        </div>
      </div>
    </section>
    
      <CallToAction />
      
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    business: file(relativePath: { eq: "home/business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    hero: file(relativePath: { eq: "home/1.0 Desktop Hero.jpg" }) {
      publicURL
    }
    challenges: file(relativePath: { eq: "home/Challenges.jpg" }) {
      childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 760)
      }
    }
    intro: file(
      relativePath: { eq: "home/Intro.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 616)
      }
    }
    two: file(
      relativePath: { eq: "home/IRS Section 280E.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 616)
      }
    }
    whyus: file(
      relativePath: { eq: "home/Why Dark Horse_.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    services: file(
        relativePath: { eq: "services/Advisory.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    cultivators: file(
      relativePath: { eq: "home/Cultivators & Manufacturers.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    dispensaries: file(
      relativePath: { eq: "home/Dispensaries.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    industry: file(
      relativePath: { eq: "home/Overall Cannabis Industry.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    taxes: file(relativePath: { eq: "Business Solutions/Tax.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    keeping: file(
      relativePath: { eq: "Business Solutions/Bookkeeping-img.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    cfo: file(relativePath: { eq: "Business Solutions/CFO-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    personal: file(relativePath: { eq: "locations/personal.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    agriculture: file(relativePath: { eq: "home/Agriculture.svg" }) {
      publicURL
    }
    biotech: file(relativePath: { eq: "home/Bio-Tech.svg" }) {
      publicURL
    }
    cannabis: file(relativePath: { eq: "home/Cannabis.svg" }) {
      publicURL
    }
    construction: file(relativePath: { eq: "home/Construction.svg" }) {
      publicURL
    }
    eCommerce: file(relativePath: { eq: "home/eCommerce.svg" }) {
      publicURL
    }
    contentCreators: file(
      relativePath: { eq: "home/Governmental & Content Creators.svg" }
    ) {
      publicURL
    }
    governmental: file(relativePath: { eq: "home/Governmental.svg" }) {
      publicURL
    }
    manufacturingDistribution: file(
      relativePath: { eq: "home/Manufacturing & Distribution.svg" }
    ) {
      publicURL
    }
    medicalDental: file(relativePath: { eq: "home/Medical & Dental.svg" }) {
      publicURL
    }
    nonProfits: file(relativePath: { eq: "home/Non-Profits.svg" }) {
      publicURL
    }
    professionalServices: file(
      relativePath: { eq: "home/Professional Services.svg" }
    ) {
      publicURL
    }
    realEstate: file(relativePath: { eq: "home/Real Estate.svg" }) {
      publicURL
    }
    retail: file(relativePath: { eq: "home/Retail.svg" }) {
      publicURL
    }
    technology: file(relativePath: { eq: "home/Technology.svg" }) {
      publicURL
    }
    exchange: file(relativePath: { eq: "home/1031 Exchange.svg" }) {
      publicURL
    }
    stockCompensation: file(
      relativePath: { eq: "home/Stock Compensation.svg" }
    ) {
      publicURL
    }
    estateTrustPlanning: file(
      relativePath: { eq: "home/Estate & Trust Planning.svg" }
    ) {
      publicURL
    }
    cryptocurrencyNFTs: file(
      relativePath: { eq: "home/Cryptocurrency & NFTs.svg" }
    ) {
      publicURL
    }
    rentalProperties: file(relativePath: { eq: "home/Rental Properties.svg" }) {
      publicURL
    }
    foreignTax: file(relativePath: { eq: "home/Foreign Tax.svg" }) {
      publicURL
    }
    military: file(relativePath: { eq: "home/Military.svg" }) {
      publicURL
    }
    multiStateFilings: file(
      relativePath: { eq: "home/Multi-State Filings.svg" }
    ) {
      publicURL
    }
    orangeSquare: file(
      relativePath: { eq: "global/decorative-orange-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    orangeCrosses: file(
      relativePath: { eq: "global/decorative-orange-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    blueSquare: file(
      relativePath: { eq: "global/decorative-blue-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    blueCrosses: file(
      relativePath: { eq: "global/decorative-blue-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
  }
`;

export default Page;
